import React, { useState, Fragment } from "react";
import { PhotographIcon, UserCircleIcon } from "@heroicons/react/solid";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import {XCircleIcon } from "@heroicons/react/outline";
import { LockClosedIcon } from "@heroicons/react/solid";
import Layout from "../components/Layout";
import MembershipForm from "../components/MembershipForm";
import { navigate } from "gatsby";

const Failed = () => {

    const [open, setOpen] = useState(true)
  return (
    <Layout>
      <div className=" py-16 sm:py-24 mt-16 bg-gray-50 border-b-2">
        <div className="sm:text-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold uppercase text-gray-600 sm:text-4xl lg:text-4xl mb-8 sm:mb-12">
            Become a Member
          </h2>
          <p className="text-lg sm:text-xl font-medium text-gray-600">
            Joining the Tasmanian Women Lawyers is easy. Choose the level of
            membership that's right for you.
          </p>
          <p className="mt-4 text-lg sm:text-xl font-medium text-gray-600">
            Simply complete the form below and proceed to making your payment
            via credit card.
          </p>
          <p className="mt-4 text-lg sm:text-xl font-medium text-gray-600">
            You can choose to have your membership renew annually or purchase a
            one time membership. The membership fee is reviewed each year at our
            AGM in December.
          </p>
        </div>
      </div>
      <main className="container mx-auto max-w-5xl my-24 px-4 w-full overflow-hidden">
        <MembershipForm />
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative [z-index:200]" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-2xl">
                    <div>
                        <div>
                          <div className="flex-auto overflow-y-auto px-4 pb-10 pt-4 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 mx-auto max-w-lg mt-6">
                            <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-red-100">
                              <XCircleIcon
                                className="h-10 w-10 text-red-600"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                              <Dialog.Title
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900"
                              >
                                Payment failed
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Your payment could not be processed at this time. Please try again later or contact us directly for further assistance.
                                </p>
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-6">
                              <button
                                className="mt-6 w-full rounded-md border border-transparent bg-twl-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-twl-purple-hover focus:outline-none focus:ring-2 focus:ring-twl-purple-hover focus:ring-offset-2"
                                onClick={() =>
                                  setOpen(false) & navigate('/become-a-member')
                                }
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </main>
    </Layout>
  );
};

export default Failed;
